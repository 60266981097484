<template>
    <div class="animated fadeIn">   
        <div class="wrapper">
            <CRow>
                <CCol lg="8">
                    <CCard>
                        <CCardHeader>
                            <font-awesome-icon icon="user"/> My Account
                            <CButton 
                                size="sm"
                                color="danger" 
                                class="float-right" 
                                @click="toggleEditDetails(), returnInformation()" 
                                v-show="edit_details"
                            >
                                <font-awesome-icon icon="window-close"/> Cancel
                            </CButton>

                            <CButton 
                                :disabled="!isValid"
                                size="sm"
                                color="success" 
                                class="float-right" 
                                v-show="edit_details" 
                                @click="updateUserDetails()"
                            >
                                <font-awesome-icon icon="save"/> Update
                            </CButton>

                            <CButton 
                                size="sm"
                                color="info" 
                                class="float-right" 
                                @click="toggleEditDetails" v-show="!edit_details"
                            >
                                <font-awesome-icon icon="edit"/> Edit
                            </CButton>
                        
                        </CCardHeader>
                        <CCardBody>
                                <CRow>
                                    <CCol lg="8">
                                        <CInput 
                                            :disabled="disable_details == 1 ? true : false"
                                            :lazy="false"
                                            :value.sync="$v.user.first_name.$model"
                                            :isValid="checkIfValid('first_name')"
                                            label="First name" 
                                            type="text" 
                                            placeholder="John" 
                                            autocomplete="off"  
                                            v-model="user.first_name"
                                        />
                                    </CCol>
                                    <CCol lg="4">
                                        <CInput 
                                            :disabled="disable_details == 1 ? true : false"
                                            label="Middle name" 
                                            type="text" 
                                            autocomplete="off"  
                                            v-model="user.middle_name"
                                        />
                                    </CCol>
                                    <CCol lg="8">
                                        <CInput 
                                            :disabled="disable_details == 1 ? true : false"
                                            :lazy="false"
                                            :value.sync="$v.user.last_name.$model"
                                            :isValid="checkIfValid('last_name')"
                                            label="Last name" 
                                            type="text" 
                                            placeholder="Doe" 
                                            autocomplete="off"  
                                            v-model="user.last_name"
                                        />
                                    </CCol>
                                    <CCol lg="4">
                                        <label> Suffix </label>
                                        <v-select 
                                            :disabled="disable_details == 1 ? true : false"
                                            label="name"
                                            v-model="user.suffix"
                                            :options="suffix"
                                        />
                                    </CCol>
                                    <CCol lg="8">
                                        <CInput 
                                            :disabled="disable_details == 1 ? true : false"
                                            :lazy="false"
                                            :value.sync="$v.user.email_address.$model"
                                            :isValid="checkIfValid('email_address')"
                                            label="Email" 
                                            type="email" 
                                            placeholder="john.doe@email.com" 
                                            autocomplete="off"  
                                            v-model="user.email_address"
                                        />
                                    </CCol>
                                    
                                    <CCol lg="8">
                                        <CFormGroup
                                            wrapperClasses="input-group pt-2"
                                            description="ex. (999) 999-9999"
                                        >
                                            <template #prepend-content>
                                            +63
                                            </template>
                                            <template #label>
                                            Contact Number
                                            </template>
                                            <template #input>
                                            <masked-input
                                                :disabled="disable_details == 1 ? true : false"
                                                type="tel"
                                                name="phone"
                                                class="form-control"
                                                v-model="user.contact_number"
                                                :mask="['(', /[1-9]/, /\d/, /\d/, ')', ' ', /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/]"
                                                :guide="true"
                                                placeholderChar="#"
                                            />
                                            </template>
                                        </CFormGroup>
                                    </CCol>
                                </CRow>
                        </CCardBody>
                    </CCard>
                    
                    <CCard>
                        <CCardHeader>
                            <font-awesome-icon icon="unlock-alt"/> My Password
                            <CButton 
                                size="sm"
                                color="danger" 
                                class="float-right" 
                                @click="toggleEditPassword()" 
                                v-show="edit_password"
                            >
                                <font-awesome-icon icon="window-close"/> Cancel
                            </CButton>

                            <CButton 
                                :disabled="!isValidPassword"
                                size="sm"
                                color="success" 
                                class="float-right" 
                                v-show="edit_password" 
                                @click="updatePassword()"
                            >
                                <font-awesome-icon icon="save"/> Update
                            </CButton>

                            <CButton 
                                size="sm"
                                color="info" 
                                class="float-right" 
                                @click="toggleEditPassword" v-show="!edit_password"
                            >
                                <font-awesome-icon icon="edit"/> Edit
                            </CButton>
                        </CCardHeader>
                        <CCardBody>
                            <CForm>
                                <CRow>
                                    <CCol lg="8">
                                        <CInput 
                                            :disabled="disable_password == 1 ? true : false"
                                            :lazy="false"
                                            :value.sync="$v.password.old.$model"
                                            :isValid="checkIfValidPassword('old')"
                                            label="Old Password" 
                                            type="password" 
                                            autocomplete="off"  
                                        >
                                            <template #prepend-content>
                                                <font-awesome-icon icon="unlock-alt"/>
                                            </template>
                                        </CInput>
                                    </CCol>
                                    <CCol lg="8">
                                        <CInput 
                                            :disabled="disable_password == 1 ? true : false"
                                            :lazy="false"
                                            :value.sync="$v.password.new.$model"
                                            :isValid="checkIfValidPassword('new')"
                                            label="New Password" 
                                            :type="password_text" 
                                            autocomplete="off"  
                                            invalidFeedback="Required password containing at least: number, uppercase and lowercase letter, 8 characters"
                                        >
                                            <template #prepend-content>
                                                <font-awesome-icon icon="lock"/>
                                            </template>
                                            
                                            <template #append>
                                                <CButton
                                                    :disabled="disable_password == 1 ? true : false"
                                                    color="primary"
                                                    @click="password_view = password_view === false ? true : false, showPassword()"
                                                >
                                                    <font-awesome-icon :icon="!password_view ? 'eye-slash' : 'eye'"/>
                                                </CButton>
                                            </template>
                                        </CInput>
                                    </CCol>
                                    <CCol lg="8">
                                        <CInput 
                                            :lazy="false"
                                            :value.sync="$v.password.repeat_new.$model"
                                            :isValid="checkIfValidPassword('repeat_new')"
                                            :disabled="disable_password == 1 ? true : false"
                                            label="Retype New Password" 
                                            :type="password_text" 
                                            autocomplete="off"  
                                            invalidFeedback="New and retype password must match"
                                        >
                                            <template #prepend-content>
                                                <font-awesome-icon icon="lock"/>
                                            </template>
                                        </CInput>
                                    </CCol>
                                </CRow>
                            </CForm>
                        </CCardBody>
                    </CCard>
                </CCol>
                <CCol lg="4">
                    <CCard>
                        <CCardHeader>
                            <font-awesome-icon icon="signature"/> My Signature
                            <CButtonGroup
                                class="float-right"
                            >
                            <CButton
                                :disabled="signature_change == 0 ? true : false"
                                v-if="this.$store.getters['getUserDetails'].signature != null"
                                size="sm"
                                color="success" 
                                class="" 
                                v-show="edit_signature" 
                                @click="updateSignature()"
                            >
                                <font-awesome-icon icon="save"/> Update
                            </CButton>
 
                            <CButton 
                                :disabled="signature_change == 0 ? true : false"
                                v-if="this.$store.getters['getUserDetails'].signature == null"
                                size="sm"
                                color="warning" 
                                class="" 
                                v-show="edit_signature" 
                                @click="createSignature()"
                            >
                                <font-awesome-icon icon="save"/> Create
                            </CButton>

                            <CButton 
                                size="sm"
                                color="info" 
                                class="pill" 
                                @click="toggleEditSignature" v-show="!edit_signature"
                            >
                                <font-awesome-icon icon="edit"/> Edit
                            </CButton>
                           
                            <CButton 
                                size="sm"
                                color="danger" 
                                class="" 
                                @click="toggleEditSignature(), returnSignatureInformation();" 
                                v-show="edit_signature"
                            >
                                <font-awesome-icon icon="window-close"/> Cancel
                            </CButton>
                            </CButtonGroup>
                        </CCardHeader>
                        <CCardBody>
                            <CRow>
                                <CCol lg="12">
                                    <center>
                                        <img
                                            :src="placeholder || signature_file != null  ? signature_file : '/static/img/signature-placeholder.png'"  
                                            class="img-user-signature"
                                            height="300px"
                                            width="300px"
                                        >
                                    </center>
                                </CCol>
                            </CRow>
                            <br>
                            <CRow>
                                <CCol sm="12">
                                        <center>                              
                                            <input  
                                                class="float-left"
                                                type="file"  
                                                ref="fileupload"  
                                                v-on:change="onFileChange" 
                                                v-if="disable_signature != 1"
                                                accept=".png"
                                            >  
                                            <CButton
                                                class="float-right" 
                                                size="sm"
                                                color="primary"  
                                                v-if="disable_signature != 1"
                                                @click="toggleSignatureModal"
                                            >
                                                <font-awesome-icon icon="file-signature"/> Draw Signature
                                            </CButton>
                                        </center>
                                </CCol>
                            </CRow>
                        </CCardBody>
                    </CCard>
                    <CCard>
                        <CCardHeader>
                            <font-awesome-icon icon="qrcode"/> DRS Viber-Bot Notifications
                        </CCardHeader>
                        <CCardBody>
                            <CRow>
                                <CCol lg="12">
                                    <iframe 
                                        scrolling="no"
                                        frameborder="0"
                                        :srcdoc="viber_qr" 
                                        style="display:block; margin: auto"
                                        onload="this.style.height=(this.contentWindow.document.body.scrollHeight+20)+'px'; this.style.width=(this.contentWindow.document.body.scrollWidth)+'px';"
                                    />
                                </CCol>
                            </CRow>
                        </CCardBody>
                    </CCard>
                </CCol>
            </CRow>
        </div>
        <CModal
            :show.sync="signature_modal"
            color="primary"
            >
            <template #header>
                <h6> <font-awesome-icon icon="file-signature"/> Draw Signature </h6>
                <CButton 
                    class="pull-right" 
                    color="light" 
                    shape="pill" 
                    size="sm"
                    @click="signature_modal = false"
                >
                    <font-awesome-icon  size="sm" icon="times"/>
                </CButton>
            </template>
            <template #footer>
                <hr>
            </template>
            <template #body-wrapper>
                <CCardBody>
                    <center>
                        <VueSignaturePad
                          style="border: 2px solid;
                                 border-radius: 5px;
                                 background-color: white"
                          width="320px"
                          height="140px"
                          ref="signaturePad"
                          :options="{onBegin: () => {$refs.signaturePad.resizeCanvas()}}"
                        />
                        <br>
                        <CButtonGroup>
                            <CButton 
                                size="sm"
                                color="primary"  
                                v-if="disable_signature != 1"
                                @click="save"
                            >
                            <font-awesome-icon icon="save"/> Submit
                            </CButton>
                            <CButton 
                                size="sm"
                                color="danger"  
                                v-if="disable_signature != 1"
                                @click="clear"
                            >
                            <font-awesome-icon icon="eraser"/> Clear
                            </CButton>
                        </CButtonGroup>
                    </center>
                </CCardBody>
            </template>
        </CModal>
    </div>
</template>
<script>
import { required, email, sameAs, minLength, helpers } from "vuelidate/lib/validators"
export default {
    name: 'MyAccount',
    data() {
        return {
            options: {
              penColor: "#000",
            },
            signature_modal: false,
            viber_qr: null,
            password_text: 'password',
            password_view: false,

            disable_details: 1,
            edit_details: false,

            disable_password: 1,
            edit_password: false,

            disable_signature: 1,
            edit_signature: false,

            
            placeholder: true,
            signature_change: 0,
            signature_file: '',
            signature_data: null,

            user: {
                id: null,
                first_name: null,
                middle_name: null,
                last_name: null,
                suffix: '',
                email_address: null,
                contact_number: "",
            },

            user_backup: {
                id: null,
                first_name: null,
                middle_name: null,
                last_name: null,
                suffix: '',
                email_address: null,
                contact_number: "",
            },

            
            suffix: [
                {"name": "Jr."},
                {"name": "Sr."},
                {"name": "II"},
                {"name": "III"}
            ],

            password: {
                old: null,
                new: null,
                repeat_new: null,
            },

        }
    },
    validations: {
        user: {
            first_name: { required, },
            last_name: { required, },
            email_address: { required, email },
        },

        password: {
            old: {required},
            new: {required, minLength: minLength(8), strongPass: helpers.regex('strongPass', /(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,}/) },
            repeat_new: {required, sameAsPassword: sameAs('new') },
        },
    },
    computed:{
        isValid () { return !this.$v.user.$invalid },
        isDirty () { return this.$v.user.$anyDirty },
        
        isValidPassword () { return !this.$v.password.$invalid },
        isDirtyPassword () { return this.$v.password.$anyDirty },
    },
    created() {
        this.getViberQR();
        this.getUserData();

        this.signature_file = JSON.parse(JSON.stringify(this.$store.getters.getUserDetails.signature ? 
            this.$store.getters.getUserDetails.signature.signature : null
        ))
    },
    methods: {
        dataURItoBlob: function(dataURI) {
            var byteString = atob(dataURI.split(',')[1]);
            var mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0];
            var ab = new ArrayBuffer(byteString.length);
            var ia = new Uint8Array(ab);
            for (var i = 0; i < byteString.length; i++) {
                ia[i] = byteString.charCodeAt(i);
            }
            return new Blob([ab], {type: mimeString});


        },
        clear() {
          this.$refs.signaturePad.clearSignature();
        },
        save() {
          const { isEmpty, data } = this.$refs.signaturePad.saveSignature();
          if(!isEmpty) {
            this.signature_data = this.dataURItoBlob(data);
            this.signature_file = data;
            this.placeholder = true;
            this.signature_change = 1;
            this.signature_modal = false;
          } else {
            this.$swal({
                icon: "warning",
                title: "Opps!",
                text: "Missing signature.",
            })
          }
        },
        toggleSignatureModal: function () {
            // this.$refs.signaturePad. openSignaturePad();
            this.signature_modal = true;
        },
        getUserData: function () {
            this.user = JSON.parse(JSON.stringify(this.$store.getters.getUserDetails))
            this.user.contact_number = this.$store.getters.getUserDetails.contact_number ?? null
            this.user.email_address = JSON.parse(JSON.stringify(this.$store.getters.getUserDetails.emails[0].email))
            this.user_backup = JSON.parse(JSON.stringify(this.user))
            this.user_backup.contact_number = this.user.contact_number
            this.user_backup.email_address = JSON.parse(JSON.stringify(this.user.email_address))
        },
        resizeIframe: function(iframe) {
            iframe.style.height = iframe.contentWindow.document.documentElement.scrollHeight + 'px';
        },
        getViberQR: function() {
            axios.post('/users/generate-viber-qr/', {validateStatus: () => true})
                .then(response => {
                    if( response.status == 200 ) {
                        this.viber_qr = response.data 
                    }
                })
        },
        validate: function () {
            this.$v.user.$touch()
        },
        validatePassword: function () {
            this.$v.password.$touch()
        },
        checkIfValid: function (fieldName) {
            const field = this.$v.user[fieldName]
            if (!field.$dirty) {
                return null
            } 
            return !(field.$invalid || field.$model === '')
        },
        validateUserDetails: function() {
            if(
                !this.user.first_name ||
                !this.user.last_name ||
                !this.user.email_address
                ) return false;
            return true;
        },
        validatePasswordInput: function() {
            if(
                !this.password.old ||
                !this.password.new ||
                !this.password.repeat_new
                ) return false;
            return true;
        },
        checkIfValidPassword: function (fieldName) {
            const field = this.$v.password[fieldName]
            if (!field.$dirty) {
                return null
            } 
            return !(field.$invalid || field.$model === '')
        },
        toggleEditDetails: function() {
            this.edit_details = !this.edit_details
            this.disable_details = (this.disable_details + 1) % 2
            if(!this.disable_details) { 
                this.validate();
            }
            else {
                this.$v.user.$reset()
            }
        },
        toggleEditPassword: function () {
            this.edit_password = !this.edit_password
            this.disable_password = (this.disable_password + 1) % 2
            this.password_view = false
            if(!this.disable_password) { 
                this.validatePassword();
            }
            else {
                this.$v.password.$reset()
            }
        },
        toggleEditSignature: function () {
            this.edit_signature = !this.edit_signature
            this.disable_signature = (this.disable_signature + 1) % 2
            if(!this.disable_signature) { 
                // this.validatePassword();
            }
            else {
                // this.$v.password.$reset()
            }
        },
        returnInformation: function () {
            this.user.email_address = JSON.parse(JSON.stringify(this.user_backup.email_address))
            this.user = JSON.parse(JSON.stringify(this.user_backup))
        },
        returnSignatureInformation: function () {
            this.signature_file = JSON.parse(JSON.stringify(this.$store.getters.getUserDetails.signature ? 
                this.$store.getters.getUserDetails.signature.signature : null
            ))
            this.signature_change = 0;
        },
        updateUserDetails: function () {
            if(JSON.stringify(this.user_backup) == JSON.stringify(this.user)) {
               this.$swal({
                    icon: "info",
                    title: "Opps!",
                    text: "No changes has been made.",
                })
                return;
            }
            if(this.validateUserDetails()){
                let signature = this.user.signature;
                let token = this.user.token;
                this.$swal({
                    icon: "warning",
                    title: "Are you sure?",
                    text: "You are trying to update your details.",
                    showCancelButton: true,
                    confirmButtonColor: "#DD6B55",
                    confirmButtonText: "Yes",
                    showLoaderOnConfirm: true,
                    allowOutsideClick: false,
                    allowEscapeKey: false,
                    preConfirm: () => { 
                        this.$Progress.start()
                        return axios.post('/users/update-my-details/' + this.user.id, this.user, {validateStatus: () => true})
                        .then(response => {
                            if( response.status == 200 ) {
                                this.$swal({
                                    icon: "success",
                                    title: "Success!",
                                    text: `${this.user.first_name} ${this.user.last_name} has been updated.`,
                                }).then(() => {
                                    let _responseData = response.data.data
                                    _responseData.signature = signature;
                                    _responseData.token = token;
                                    this.$store.commit("SET_USER_DETAILS", _responseData);
                                    this.toggleEditDetails();
                                    this.$Progress.finish()
                                    this.getUserData();
                                })
                            }
                        })
                    }
                    }).then((result) => {
                    if(!result.value){
                        this.$swal('Cancelled!','','error');
                    }
                })
                return;
            }
            this.$swal({
                toast: true,
                position: 'top-right',
                showConfirmButton: false,
                timer: 3000,
                icon: 'error',
                title: 'Please fill up required fields.',
                timerProgressBar: true,
            }) 
            return;
        },
        showPassword: function() {
            if(this.password_view) {
                this.password_text = 'text'
            } else {
                this.password_text = 'password'
            }
        },
        updatePassword: function() {
            if(this.validatePasswordInput()){
                this.$swal({
                    icon: "warning",
                    title: "Are you sure?",
                    text: "You are trying to update your password.",
                    showCancelButton: true,
                    confirmButtonColor: "#DD6B55",
                    confirmButtonText: "Yes",
                    showLoaderOnConfirm: true,
                    allowOutsideClick: false,
                    allowEscapeKey: false,
                    preConfirm: () => { 
                        let data = {
                            old_password:this.password.old,
                            new_password: this.password.new,
                            new_confirm_password: this.password.repeat_new,
                        }
                        this.$Progress.start()
                        return axios.post('/users/change-password', data, {validateStatus: () => true})
                        .then(response => {
                            if( response.status == 200 ) {
                                this.$swal({
                                    icon: "success",
                                    title: "Success!",
                                    text: "Password updated. Would you like to try your new password?",
                                    showCancelButton: true,
                                    confirmButtonColor: "#DD6B55",
                                    confirmButtonText: "Yes",
                                }).then((result) => {
                                    if(result.value){
                                        this.$Progress.finish()
                                        this.logoutUser(this.$store.getters.getOrganizationName);
                                    } else {
                                        this.$Progress.finish()
                                        this.password = this.clearPassword();
                                        this.toggleEditPassword();
                                    }
                                })
                            }
                        })
                    }
                    }).then((result) => {
                    if(!result.value){
                        this.$swal('Cancelled!','','error');
                    }
                })
                return;
            } 
            this.$swal({
                    toast: true,
                    position: 'top-right',
                    showConfirmButton: false,
                    timer: 3000,
                    icon: 'error',
                    title: 'Please fill up required fields.',
                    timerProgressBar: true,
                }) 
            return;
        },
        clearPassword: function () {
            return {
                old: null,
                new: null,
                repeat_new: null,
            }
        },
        onFileChange:function (e)
        {
            let files = e.target.files || e.dataTransfer.files;
            if (!files.length) {
                this.returnSignatureInformation()
                return;
            }

            if(files[0].type != 'image/png') 
            {
                this.$swal({
                    customClass: 'ta-left',
                    toast: true,
                    position: 'top-right',
                    showConfirmButton: false,
                    timer: 5000,
                    icon: 'error',
                    title: `Invalid file. Only accept .png`,
                    timerProgressBar: true,
                })
                this.clearSignature();
                this.returnSignatureInformation()
                return;
            }
            

            if (files[0].size / Math.pow(1024,1) > 4000) {
                this.$swal({
                    customClass: 'ta-left',
                    toast: true,
                    position: 'top-right',
                    timer: 5000,
                    icon: 'error',
                    title: `File is too large. Maximum size is 4MB.`,
                    showConfirmButton: false,
                    timerProgressBar: true,
                })
                this.clearSignature();
                this.returnSignatureInformation()
                return;
            }

            let height;
            let width;

            let img = new Image()
            img.src = window.URL.createObjectURL(this.$refs.fileupload.files[0])
            img.onload = () => {
                if (img.height < 120 || img.width < 120) {
                    this.$swal({
                        customClass: 'ta-left',
                        toast: true,
                        position: 'top-right',
                        showConfirmButton: false,
                        timer: 5000,
                        icon: 'error',
                        title: `File is too small. <br>Minimum Height x Width should be 120x120.`,
                        timerProgressBar: true,
                    })
                    this.clearSignature();
                    this.returnSignatureInformation()
                    return;
                }
            }

            this.createImage(files[0]);
            this.placeholder = true;
            this.signature_change = 1;
        },
        createImage:function (file)
        {
            this.signature_data = file

            let reader = new FileReader();
            reader.onload = (e) => {
                this.signature_file = e.target.result;
            };
            reader.readAsDataURL(file);
        },
        clearSignature:function()
        {
            const input = this.$refs.fileupload;
            input.type = 'text';
            input.type = 'file';
        },
        createSignature: function () {
            this.$swal({
                icon: "question",
                html: ` <h4>You are trying to create your signature.</h4>
                        <h5>Please type your password to proceed.</h5>`,
                input: 'password',
                inputAttributes: {
                    autocapitalize: 'off'
                },
                allowOutsideClick: false,
                allowEscapeKey : false,
                showCancelButton: true,
                confirmButtonColor: "#DD6B55",
                confirmButtonText: "Proceed",
                showLoaderOnConfirm: true,
                focusConfirm:true,
                preConfirm: (user_password) => { 
                    this.$Progress.start()
                    
                    if(!user_password) {
                        this.$swal.showValidationMessage('Password cannot be empty.')
                        this.$Progress.fail()
                        return 
                    }

                    let data = new FormData();
                    data.append('signature_data', this.signature_data);
                    data.append('password', user_password);

                    return axios.post('/signatures/store', data, {validateStatus: () => true}, { headers: {'Content-Type': 'multipart/form-data' }})
                    .then(response => {
                        this.$Progress.finish()
                        if(response.status == 200) {
                            let signature_data = {
                                signature: response.data.data
                            }
                            this.signature_file = response.data.data
                            this.$store.getters.getUserDetails.signature = signature_data
                            let user_data = this.$store.getters.getUserDetails;
                            this.$store.commit("SET_USER_DETAILS", user_data);
                            this.toggleEditSignature();

                            
                            this.$swal({
                                icon: "success",
                                title: "Success!",
                                text: "Signature created successfully.",
                            })
                        }
                    })
                }
            })
        },
        updateSignature: function () {
            this.$swal({
                icon: "question",
                html: ` <h4>You are trying to update your signature.</h4>
                        <h5>Please type your password to proceed.</h5>`,
                
                input: 'password',
                inputAttributes: {
                    autocapitalize: 'off'
                },
                allowOutsideClick: false,
                allowEscapeKey : false,
                showCancelButton: true,
                confirmButtonColor: "#DD6B55",
                confirmButtonText: "Proceed",
                showLoaderOnConfirm: true,
                preConfirm: (user_password) => { 
                    this.$Progress.start()
                    
                    
                    if(!user_password) {
                        this.$swal.showValidationMessage('Password cannot be empty.')
                        this.$Progress.fail()
                        return 
                    }

                    let data = new FormData();
                    data.append('signature_data', this.signature_data);
                    data.append('password', user_password);


                    return axios.post('/signatures/update', data, {validateStatus: () => true}, { headers: {'Content-Type': 'multipart/form-data' }})
                    .then(response => {
                        this.$Progress.finish()
                        if(response.status == 200) {
                            let signature_data = {
                                signature: response.data.data
                            }
                            this.signature_file = response.data.data
                            this.$store.getters.getUserDetails.signature = signature_data
                            let user_data = this.$store.getters.getUserDetails;
                            this.$store.commit("SET_USER_DETAILS", user_data);
                            this.toggleEditSignature();

                            this.$swal({
                                icon: "success",
                                title: "Success!",
                                text: "Signature updated successfully.",
                            })
                        }
                    })

                }
            })
        }
    },
    watch: {
        signature_file: function (value) {
            this.placeholder = !value ? false : true;
        }
    }
}
</script>
<style>
#signature {
  border: 2px solid;
  border-radius: 5px;
  background-image: linear-gradient(white, white),
    radial-gradient(circle at top left, #4bc5e8, #9f6274);
  background-origin: border-box;
  background-clip: content-box, border-box;
}

.container {
  width: "100%";
  padding: 8px 16px;
}

.buttons {
  display: flex;
  gap: 8px;
  justify-content: center;
  margin-top: 8px;
}
</style>